import {
  GET_TEACHERS,
  GET_USER_FWA_EVENTS,
  SET_SUBSCRIPTION_STATE_LOADING,
  SET_SESSION_ACCESS,
  SET_SESSION_ACCESS_ERROR,
  SET_STRIPE_SESSION_DATA,
} from '../types'

const SubscriptionReducer = (state, action) => {
  switch (action.type) {
    case GET_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
      }

    case GET_USER_FWA_EVENTS:
      return {
        ...state,
        userFWAEvents: action.payload,
      }
    case SET_SUBSCRIPTION_STATE_LOADING:
      return {
        ...state,
        subscriptionStateLoading: action.payload,
      }

    case SET_SESSION_ACCESS:
      return {
        ...state,
        meetingUrl: action.payload,
      }

    case SET_SESSION_ACCESS_ERROR:
      return {
        ...state,
        isSessionAccessError: action.payload,
      }
    case SET_STRIPE_SESSION_DATA:
      return {
        ...state,
        stripeSessionDetails: action.payload,
      }
    default:
      return state
  }
}

export default SubscriptionReducer
