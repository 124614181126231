import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n/i18n'

import { Auth0Provider } from 'utils/react-auth0-spa'
import UserProvider from 'context/user/UserProvider'
import SubscriptionProvider from 'context/subscription/SubscriptionProvider'
import ApolloProviderWrapper from './src/ApolloProviderWrapper'

/*
  Route user to the correct place after login
*/
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <Auth0Provider
        domain={process.env.GATSBY_AUTH0_DOMAIN}
        client_id={process.env.GATSBY_AUTH0_CLIENT_ID}
        redirect_uri={process.env.GATSBY_WEBSITE_URL}
        audience={process.env.GATSBY_AUTH0_AUDIENCE}
        onRedirectCallback={onRedirectCallback}
      >
        <UserProvider>
          <SubscriptionProvider>
            <ApolloProviderWrapper>{element}</ApolloProviderWrapper>
          </SubscriptionProvider>
        </UserProvider>
      </Auth0Provider>
    </I18nextProvider>
  )
}
