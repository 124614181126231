import React from 'react'
import PropTypes from 'prop-types'
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'

import { useAuth0 } from 'utils/react-auth0-spa'

const ApolloProviderWrapper = ({ children, ssr }) => {
  const { getTokenSilently, auth0Loading, isAuthenticated } = useAuth0()

  // need to provide a fetcher for server side rendering (ssr)
  const apolloHttpLink = createHttpLink({
    uri: process.env.GATSBY_FIREBASE_COURSE_MANAGEMENT_FUNCTION_URL,
    ...(ssr && { fetch }),
  })

  const apolloAuthLink = setContext(async (_, { headers }) => {
    const token =
      auth0Loading || !isAuthenticated
        ? ''
        : `Bearer ${await getTokenSilently()}`

    return {
      headers: {
        ...headers,
        Authorization: token,
      },
    }
  })

  const client = new ApolloClient({
    link: apolloAuthLink.concat(apolloHttpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

ApolloProviderWrapper.defaultProps = {
  ssr: false,
}

ApolloProviderWrapper.propTypes = {
  ssr: PropTypes.bool,
}

export default ApolloProviderWrapper
