// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-cancel-js": () => import("./../../../src/pages/payment/cancel.js" /* webpackChunkName: "component---src-pages-payment-cancel-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-select-your-class-js": () => import("./../../../src/pages/select-your-class.js" /* webpackChunkName: "component---src-pages-select-your-class-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-uniminuto-index-js": () => import("./../../../src/pages/uniminuto/index.js" /* webpackChunkName: "component---src-pages-uniminuto-index-js" */),
  "component---src-pages-uniminuto-join-js": () => import("./../../../src/pages/uniminuto/join.js" /* webpackChunkName: "component---src-pages-uniminuto-join-js" */),
  "component---src-pages-uniminuto-worksheets-js": () => import("./../../../src/pages/uniminuto/worksheets.js" /* webpackChunkName: "component---src-pages-uniminuto-worksheets-js" */)
}

