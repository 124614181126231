import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from 'i18n/resources/en'
import es from 'i18n/resources/es'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources: {
      en,
      es,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
