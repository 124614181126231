import {
  LOAD_USER_STATE,
  UPDATE_USERNAME,
  DELETE_USER,
  UPDATE_USER_PRODUCT_ACCESS,
  SET_USER_STATE_LOADING,
  UPDATE_USER_FWA_PRODUCT,
  UPDATE_EMAIL,
  TOGGLE_SHOW_DISCOUNT,
} from '../types'

const UserReducer = (state, action) => {
  switch (action.type) {
    case LOAD_USER_STATE:
      return {
        ...state,
        username: action.payload.username,
        subscriptionInfo: action.payload.subscriptionInfo,
        firestoreUserObject: action.payload,
      }
    case UPDATE_USERNAME:
      return {
        ...state,
        username: action.payload,
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    case DELETE_USER:
      return {
        ...state,
        username: '',
        subscriptionInfo: null,
        productAccess: null,
      }
    case UPDATE_USER_PRODUCT_ACCESS:
      return {
        ...state,
        productAccess: action.payload,
      }
    case SET_USER_STATE_LOADING:
      return {
        ...state,
        userStateLoading: action.payload,
      }
    case UPDATE_USER_FWA_PRODUCT:
      const updatedUser = state.firestoreUserObject

      if (Array.isArray(updatedUser.fwaPurchases)) {
        // if the user already has fwa purchases, add this new one
        updatedUser.fwaPurchases.push(action.payload)
      } else {
        // if the user does not already have fwa purchases, start the array
        updatedUser.fwaPurchases = [action.payload]
      }
      return {
        ...state,
        firestoreUserObject: updatedUser,
      }
    case TOGGLE_SHOW_DISCOUNT:
      return {
        ...state,
        showDiscount: action.payload,
      }
    default:
      return state
  }
}

export default UserReducer
