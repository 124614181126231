const Spanish = {
  modalOffer: {
    title: '¡Feliz Navidad!',
    text:
      '¡Proporcione este cupón cuando realice el pago para obtener un 50% de descuento en cualquier curso!',
  },
  general: {
    error: 'Se ha producido un error. Inténtalo de nuevo.',
  },
  header: {
    greeting: 'Hola',
    home: 'Inicio',
    courses: 'Cursos',
    account: 'Mi cuenta',
    signIn: 'Iniciar sesión',
    signOut: 'Salir',
    languagePlaceholder: 'Idioma',
    howItWorks: 'Cómo funciona',
    enterprise: 'Para empresas',
  },
  footer: {
    footerText: 'FluentWorlds Academy | Todos los derechos reservados.',
  },
  indexPage: {
    seoTitle: 'Clases de Ingles en Linea - Principiantes a Profesionales',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    videoNotSupported: 'Su navegador no soporta la etiqueta de vídeo.',
    LearnInMetaverseTitle: 'Aprenda inglés en el Metaverso',
    LearnInMetaverseSubtitle:
      'La experiencia completa de aprendizaje de idiomas',
    howItWorksTitle: 'Cómo funciona',
    howItWorksSubtitle:
      'Aprendizaje de idiomas en vivo en nuestros mundos virtuales en línea',
    howItWorksCard1Title:
      'Aprendizaje de idiomas en nuestros mundos virtuales interactivos en línea',
    howItWorksCard1Description:
      'Elija grupos pequeños o clases individuales. Hable inglés con compañeros de su nivel o reciba tutorías individuales con nuestros instructores expertos que le guiarán en su viaje hacia la fluidez en inglés.',
    howItWorksCard2Title: 'Aprenda inglés en el metaverso',
    howItWorksCard2Description:
      'Los estudios muestran que el aprendizaje virtual en entornos inmersivos 3D es mucho más efectivo que en las aulas tradicionales o la capacitación tradicional en línea. La vida no es bidimensional, entonces, por qué su curso de inglés debería serlo?',
    howItWorksCard3Title: 'Aprendizaje Acelerado',
    howItWorksCard3Description:
      'Lleve su aprendizaje del idioma Inglés más allá. Acceda a nuestros mas calificados productos de aprendizaje: PerfectAccent y la aplicación FluentWorlds. Inmersión más reconocimiento de voz = una experiencia única de aprendizaje aumentado.',
    howItWorksLearnMore: 'Aprenda más',
    titleAndImageTitle: 'Sumérjase en nuestro Metaverso',
    titleAndImageSubtitle: 'La experiencia completa de aprendizaje de idiomas',
    meetOurStudentsTitle: 'Conozca a Nuestros Estudiantes',
    benefitsTitle: 'Beneficios de Aprender Con FluentWorlds',
    benefitsSmallTitle1: 'Empiece a Aprender Inglés Hoy',
    benefitsSmallDescription1:
      'Reserve su curso hoy y comience a aprender de inmediato con acceso a la aplicación FluentWorlds. La aplicación de aprendizaje de idiomas más emocionante, inmersiva y efectiva que existe.',
    benefitsSmallTitle2: 'Aprenda a su manera, en su tiempo',
    benefitsSmallDescription2:
      'Tome el control total de su aprendizaje de idiomas. Elija entre nuestras diferentes opciones, el curso que mejor se adapte a sus necesidades. Personalice su personaje en el Metaverso y decida si su cámara web está encendida o apagada.',
    benefitsSmallTitle3: 'Cree un Hábito Ganador',
    benefitsSmallDescription3:
      'Estudiar inglés en la Academia FluentWorlds nunca es aburrido. Ganará fluidez simulando experiencias de la vida real. Esto le ayuda a progresar rápidamente y retener el idioma.',
    benefitsSmallTitle4: 'Haga que su aprendizaje de idiomas vaya más allá',
    benefitsSmallDescription4:
      'Nuestros profesores son expertos en enseñar en el Metaverso. Lo guiarán cuidadosamente a través de nuestro plan de estudios de inglés diseñado de una manera creativa, memorable y efectiva.',
    comingSoon: 'Vídeo próximamente',
    closeVideo: '[Cerrar vídeo]',
  },
  buyNowButton: {
    buyNowButton: 'Compre ahora',
  },
  learnWithoutLimits: {
    learnWithoutLimitsTitle: 'Aprenda Sin Límites en el Metaverso',
    learnWithoutLimitsSubtitle: 'Elija su curso',
    learnWithoutLimitsGeneralHeader: 'Inglés General',
    learnWithoutLimitsGeneralDescription:
      'Mejore su Inglés cotidiano practicando el idioma que necesita en entornos realistas, como una tienda de comestibles, un aeropuerto, un banco o una oficina gubernamental. Logre un gran progreso rápidamente.',
    learnWithoutLimitsBusinessHeader: 'Inglés de Negocios',
    learnWithoutLimitsBusinessDescription:
      'Conviértase en un comunicador empresarial eficaz y exitoso obteniendo mejores resultados en su lugar de trabajo. Desde la sala de juntas hasta el centro de conferencias, aprenda habilidades reales para impulsar su carrera.',
    learnWithoutLimitsAcademicHeader: 'Inglés Académico',
    learnWithoutLimitsAcademicDescription:
      'Mejore sus habilidades y calificaciones avanzando en sus estudios desde nuestros ambientes académicos virtuales. Mejore sus conocimientos académicos y gane confianza para estudiar en Inglés.',
    learnWithoutLimitsIndividualHeader: 'Inglés Individual',
    learnWithoutLimitsIndividualDescription:
      'Aprendizaje creado solo para usted. Obtenga lecciones individuales privadas y enfocadas con uno de nuestros maestros expertos. Explore entornos virtuales y logre sus objetivos lingüísticos personales.',
  },
  meetOurStudents: {
    meetOurStudentsBuyNow: 'Compra ahora',
    comingSoon: 'Vídeo próximamente',
    closeVideo: '[Cerrar vídeo]',
  },
  howItWorksPage: {
    seoTitle: 'Clases de Ingles en Linea - Principiantes a Profesionales',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    howDoesFWAWorkTitle: 'Aprenda Inglés en el Metaverso',
    howDoesFWAWorkSubtitle: 'La experiencia completa de aprendizaje de idiomas',
    howDoesCard1Title: 'Elija el curso mas adecuado para usted',
    howDoesCard1Description:
      'Toma un breve examen de Inglés para ayudarle a decidir',
    howDoesCard2Title: 'Aprenda de inmediato con nuestras aplicaciones',
    howDoesCard2Description: 'Todo el acceso a FluentWorlds y PerfectAccent',
    howDoesCard3Title: 'Comience clases en vivo con su profesor',
    howDoesCard3Description: 'Clases inmersivas completas en el Metaverso',
    howDoesCard4Title: 'Haga seguimiento a su progreso',
    howDoesCard4Description:
      'Supervise su mejoramiento para obtener los mejores resultados',
    howDoesCard5Title: 'Resultados y Certificación',
    howDoesCard5Description: 'Descargue su certificado oficial',
    howDoesCard6Text:
      'Comience su viaje hoy... Estará en camino hacia un futuro más brillante.',
    immersiveLearningTitle: 'Aprendizaje Inmersivo Con Profesores de Inglés',
    immersiveLearningSubtitle:
      'Perfeccione su acento con nuestros profesores Norteamericanos y aplicaciones galardonadas.',
    immersiveCardBullet1:
      'Escuche y hable el Inglés del mundo real, ya sea individualmente o en grupos pequeños. Colabore con sus amigos y con estudiantes de todo el mundo',
    immersiveCardBullet2:
      'Las clases en línea se llevan a cabo en más de 50 entornos virtuales únicos.',
    immersiveCardBullet3:
      'Practique y perfeccione su pronunciación en cualquier momento con nuestra PerfectAccent App',
    immersiveCardBullet4:
      'La aplicación FluentWorlds tiene una calificación de 5 estrellas en iTunes de Apple',
    groupOrPrivateTitle: 'Clases Grupales o Clases Privadas',
    groupOrPrivateSubtitle: 'Choose the way you learn best',
    groupCardTitle: 'Clases Grupales',
    groupCardBullet1:
      'Aprenda con otros en un ambiente relajado y de mucho apoyo.',
    groupCardBullet2:
      'Se ofrecen clases de una hora cada semana. Elija el horario y la frecuencia que más le convenga',
    groupCardBullet3:
      'Las clases grupales generalmente tienen entre 10 y 15 estudiantes.',
    groupCardBullet4: 'Elige entre 5, 10 o 20 lecciones al mes',
    groupCardBullet5:
      'Colabore con otros estudiantes en nuevas perspectivas y puntos de vista',
    privateCardTitle: 'Clases Privadas Individuales',
    privateCardBullet1:
      'Las clases individuales maximizan el tiempo con su instructor. Usted es el centro de la clase. Una excelente manera de eliminar las distracciones y aumentar la productividad.',
    privateCardBullet2: 'Elija el horario y la frecuencia que más le convenga',
    privateCardBullet3:
      'El plan de estudios se adapta a sus necesidades y objetivos específicos.',
    privateCardBullet4: 'Disfrute de comentarios inmediatos y útiles',
    privateCardBullet5: 'Elija entre 5, 10 o 20 lecciones al mes',
    titleAndImageTitle: 'Unase a miles de estudiantes felices',
    titleAndImageSubtitle: 'Estará satisfecho de haberlo hecho!',
  },
  accountPage: {
    seoTitle: 'FluentWorlds Academy - Mi Cuenta',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    individualClassCardMessage:
      'Su tutor se comunicará con usted con más detalles.',
    tooEarlyToJoinMessage:
      'No se puede entrar al aula hasta el primer día de clases.',
    tooLateToJoinMessage: 'Esta clase ha terminado.',
    accountDetailsNav: 'Detalles de la Cuenta',
    yourClassesNav: 'Sus clases',
    signOutNav: 'Desconectarse',
    yourClassesHeader: 'Sus clases',
    classStatusLabel: 'Estado: ',
    classStatusRegistered: 'Comprada',
    classStatusPurchased: 'Registrada',
    classStatusCompleted: 'Completada',
    classStartDateLabel: 'Fecha de inicio: ',
    classJoinButton: 'Unirse a clase',
    classSelectButton: 'Select Class',
    accountDetilsHeader: 'Detalles de la cuenta:',
    accountDetailsSubHeader:
      'Nota: si usted cambia su correo electrónico o nombre de usuario, deberá iniciar la sesión nuevamente.',
    showUsernameLabel: 'Nombre de Usuario: ',
    updateUsername: 'Actualizar Nombre de Usuario: ',
    usernameToastError: 'El nuevo nombre de usuario está vacío',
    showEmailLabel: 'Email: ',
    updateEmail: 'Actualizar Email: ',
    emailToastError: 'El nuevo email está vacío',
    updateButton: 'Actualizar',
    profileDetailsHeader: 'Detalles del Perfil',
    profileDetailsSubHeader:
      'Si desea actualizar los detalles de su perfil, complete el formulario a continuación y haga clic en "Actualizar mi perfil"',
    showFullNameLabel: 'Nombre Completo: ',
    showNativeLanguageLabel: 'Lengua Materna: ',
    showAgeLabel: 'Edad: ',
    showPronounsLabel: 'Pronombres: ',
    selectFirstItem: '-- seleccione una opción--',
    pronounsSheHer: 'Ella',
    pronounsHeHim: 'Él',
    pronounsTheyThem: 'Elle',
    pronounsPreferNotToSay: 'Prefiero no decir',
    showEnglishProficiencyLabel: 'Dominio del Inglés: ',
    proficiencyLevel1: 'Nivel 1 (A1/B1)',
    proficiencyLevel2: 'Nivel 2 (B1)',
    proficiencyLevel3: 'Nivel 3 (B2+)',
    proficiencyUnsure: 'No sé',
    updateProfileButton: 'Actualizar Detalles del Perfil',
    loading: 'Cargando...',
    processing: 'Procesando...',
    notLoggedIn:
      'No ha iniciado sesión. Inicie sesión o regístrese para ver los detalles de su cuenta.',
    formError: 'Debe completar todos los detalles del perfil antes de enviar.',
    noClassesMessage:
      'Aún no has comprado ninguna clase. Cuando lo hagas, los verás aquí.',
    notApplicable: 'N/A',
    usernameSuccessMessage: 'Nombre de usuario actualizado correctamente',
    emailSuccessMessage: 'Email actualizado correctamente',
    profileSuccessMessage: 'Perfil actualizado correctamente',
  },
  selectAClassPage: {
    seoTitle: 'FluentWorlds Academy - Elige Tu Clase',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    selectAClassTitle:
      'Seleccione su horario de clases preferido de las opciones a continuación.',
    startDate: 'Fecha de Inicio: ',
    endDate: 'Fecha Final: ',
    classTimes: 'Horarios de Clases Semanales: ',
    // TODO: need to get translations for all days of the week and logic to set them.
    classDescription: 'Descripción: ',
    classStudents: 'Estudiantes: ',
    loading: 'Cargando...',
    register: 'Registrarse',
    duplicateRegistrationMessage:
      'Ya estás registrado para esta clase. No puedes registrarte para la misma clase dos veces.',
  },
  coursesPage: {
    seoTitle: 'FluentWorlds Academy - Cursos de Ingles',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    processing: 'Procesando...',
    mustBeSignedInMessage:
      'Debe iniciar sesión para realizar una compra. Si aún no tiene una cuenta, use el botón "Iniciar sesión" de arriba para crear una cuenta gratuita.',
    coursesPageTitle: 'Cursos de Inglés',
    coursesPageSubtitle: 'Tenemos un curso para usted!',
    coursesPageInstructions:
      'Seleccione su curso utilizando los menús desplegables a continuación. Elija entre clases generales, académicas o de negocios, u obtenga un tutor individual.',
    generalEnglishTitle: 'Inglés General',
    businessEnglishTitle: 'Inglés de Negocios',
    academicEnglishTitle: 'Inglés Académico',
    individualEnglishTitle: 'Inglés Individual',
    liteLabel: 'Ligero',
    classicLabel: 'Clásico',
    premiumLabel: 'Premium',
    completeLabel: 'Completo',
    nonIndividualLiteDuration: '4 Semanas / 8 Clases',
    nonIndividualClassicDuration: '8 Semanas / 16 Clases',
    nonIndividualPremiumDuration: '12 Semanas / 24 Clases',
    nonIndividualCompleteDuration: '16 Semanas / 32 Clases',
    individualLiteDuration: '5 Horas de Tutoría',
    individualClassicDuration: '10 Horas de Tutoría',
    individualPremiumDuration: '15 Horas de Tutoría',
    checkbox1:
      'Lecciones en vivo atractivas e inmersivas con nuestros maestros expertos',
    checkbox2:
      'Acceso ilimitado a lecciones interactivas de autoaprendizaje en la aplicación FluentWorlds.',
    checkbox3: 'Prueba de nivel y certificación de fin del curso',
    upgradeCheckboxesTitle1:
      'Disponible en paquetes Clásico, Premium o Completo',
    upgradeCheckboxesTitle2: 'Disponible en paquetes Clásico o Premium',
    checkbox4: 'Acceso a PerfectAccent',
    checkbox5_1: 'Tutoría de fin de curso con su profesor',
    checkbox5_2: '(Solo para cursos grupales Premium y Completo)',
    moreBelow: 'Más Información Del Curso a Continuación',
    selectCategory: 'Seleccionar Categoría:',
    selectOption: 'Seleccionar Opción:',
    generalEnglishInfoHeader: 'Aprenda Inglés General en el Metaverso',
    generalEnglishInfoTitle: 'Lecciones prácticas para la vida cotidiana',
    generalEnglishInfoDescription1:
      'Haga fácil cada día de su vida cotidiana aprendiendo el vocabulario que realmente usará. Ya sea que necesite ayuda para viajar, hacer un pedido en un restaurante o simplemente ir al supermercado, nuestro curso de inglés lo convertirá en un experto en poco tiempo.',
    businessEnglishInfoHeader: 'Aprenda Inglés de negocios en el Metaverso',
    businessEnglishInfoTitle: 'Para todas sus necesidades profesionales',
    businessEnglishInfoDescription1:
      'Trabajar internacionalmente requiere una amplia gama de habilidades y competencias lingüísticas. Los líderes empresariales deben realizar presentaciones de manera efectiva, contribuir en reuniones, gestionar negociaciones, escribir correos electrónicos efectivos y hablar por teléfono o en línea. Nuestros cursos de Inglés comercial lo preparan para tener éxito en todos estos escenarios.',
    academicEnglishInfoHeader: 'Aprenda Inglés Académico en el Metaverso',
    academicEnglishInfoTitle: 'La vida en el campus nunca fue más fácil',
    academicEnglishInfoDescription1:
      'Avance en sus estudios con nuestro Inglés Académico. Este curso cubre vocabulario exclusivo de su entorno universitario. Gane confianza en sus habilidades para hablar y escribir. Al usar estas habilidades avanzadas, se destacará en áreas importantes, como presentaciones de grupos de investigación, análisis crítico y escritura extendida.',
    individualEnglishInfoHeader: 'Aprenda Inglés Individual en el Metaverso',
    individualEnglishInfoTitle: 'Su propia guía personal para el Inglés',
    individualEnglishInfoDescription1:
      'Nuestro servicio de tutoría individual se adapta a usted. Las lecciones se basan en sus objetivos y necesidades específicas. Las lecciones están disponibles todo el día y puede elegir el mejor momento para usted, incluidas las noches y los fines de semana.',
    InfoBullet1: 'Clases de conversación en Inglés en vivo y en línea',
    InfoBullet2: 'Profesores Estadounidenses expertos',
    InfoBullet3: 'Inmersión en lugares del mundo real en un videojuego',
    InfoBullet4:
      'Rápido y divertido - Aprendizaje superior a excelentes precios',
    InfoBullet5:
      'Se ha demostrado que es la forma más rápida de aprender a hablar en Inglés',
    InfoSeparator1: 'Elija entre',
    InfoSeparatorNumberThree: 'tres',
    InfoSeparatorNumberFour: 'cuatro',
    InfoSeparator2: 'programas diferentes:',
    InfoBullet6: '4 semanas - repase sus habilidades en Inglés',
    InfoBullet7: '8 semanas - acelere su aprendizaje',
    InfoBullet8: '12 semanas - perfecto para subir de nivel',
    InfoBullet9: '16 semanas - nuestro curso completo',
    InfoBullet6Individual: '5 Horas de Tutoría Personal',
    InfoBullet7Individual: '10 Horas de Tutoría Personal',
    InfoBullet8Individual: '15 Horas de Tutoría Personal',
    studentComponent: 'Lo que dicen nuestros estudiantes',
    titleAndImageTitle: 'Unase a miles de estudiantes felices',
    titleAndImageSubtitle: 'Estará satisfecho de haberlo hecho!',
    backToTop: 'Volver Arriba',
    buyNowButton: 'Compra Ahora',
  },
  enterprisePage: {
    seoTitle: 'FluentWorlds Academy - Para Empresas',
    seoDescription:
      'Se ha demostrado que la inmersión es la forma más rápida de aprender inglés. Nuestras clases de inglés online en vivo, con profesores americanos expertos, te permite experimentar las clases en un mundo 3D',
    BusinessInTheMetaverseTitle: 'Inglés de negocios en El Metaverso',
    BusinessInTheMetaverseSubtitle:
      'Capacitación inmersiva en idiomas para su personal en impresionantes entornos virtuales en 3D. Se ha comprobado que nuestro sistema acelera el aprendizaje y brinda excelentes resultados.',
    BusinessInTheMetaverseContactUs: 'Querer aprender más? Contáctenos en ',
    ourSuiteSweetTitle: 'Nuestro Sistema. En Tercera Dimensión.',
    ourSuiteSweetSubtitle:
      'La experiencia mas completa de aprendizaje de idiomas',
    fluentWorldsProductTitle: 'FluentWorlds',
    fluentWorldsProductDescription:
      'La aplicación de aprendizaje de idiomas más emocionante, inmersiva y efectiva que existe.',
    FWAProductTitle: 'FluentWorlds Academy / 3Dmeet',
    FWAProductDescription:
      'Tutoría personal experta y llamativas conferencias web al alcance de su mano',
    perfectAccentProductTitle: 'Perfect Accent',
    perfectAccentProductDescription:
      'Una guía de acento precisa e impulsada por un avanzado sistema de aprendizaje automatizado.',
    acceleratedLearningHeader: 'Aprendizaje acelerado',
    acceleratedLearningTitle1: 'Reúna a todos los miembros de su Equipo',
    acceleratedLearningDescription1:
      'No importa dónde se encuentren, su equipo colaborará, aprenderá y progresará en nuestros atractivos entornos virtuales. Reúnalos para mejorar sus habilidades de comunicación en inglés.',
    acceleratedLearningTitle2: 'Aprenda inglés en 3D',
    acceleratedLearningDescription2:
      'Se ha demostrado que aprender y recordar un idioma es más efectivo en entornos 3D que en las aulas tradicionales o la capacitación en línea tradicional.',
    acceleratedLearningTitle3: 'La solución completa',
    acceleratedLearningDescription3:
      'Con acceso a nuestro conjunto de productos de aprendizaje, incluido PerfectAccent, nuestra combinación única de inmersión en vivo, más nuestro sistema de reconocimiento de su voz, equivale a una experiencia de aprendizaje mucho más rápida. Realice fácilmente un seguimiento del progreso en el aprendizaje con FluentViewer, nuestra efectiva herramienta en línea que permite a los administradores y maestros ver el progreso de los estudiantes.',
    acceleratedLearningTitle4: 'A su medida',
    acceleratedLearningDescription4:
      'Marque nuestros entornos con su logotipo y colores, o permítanos construir un mundo completamente personalizado para que su equipo aprenda en el.',
    acceleratedLearningSeeHowItWorks: 'Vea cómo funciona su curso',
    CEOTestimonialDescription:
      'A través de la aplicación 3DMeet, sentimos que estamos inmersos en el aula con el maestro. Es valioso compartir la experiencia con otras personas lo que permite trasladar el diálogo sobre la vida cotidiana al idioma inglés. Además, la aplicación FluentWorlds es una herramienta virtual que podemos usar de forma independiente para practicar la tarea después de clase. Esto nos permite fortalecer aún más nuestro aprendizaje porque no es un entrenamiento lineal; es una nueva experiencia dinámica, lúdica y con un objetivo educativo.',
    MasteryMethodologyTitle: 'Nuestra Metodología Crea Maestría',
    MasteryMethodologySubtitle:
      'Prueba de que la inmersión produce una experiencia de aprendizaje más efectiva y agradable',
    MasteryMethodologyOverlayTitle:
      'Professors Use Immersion For Better Learning',
    MasteryMethodologyOverlayDescription:
      'Nuestros profesores utilizan entornos inmersivos del mundo virtual para simular experiencias del mundo real. Los estudios muestran que el lenguaje practicado en contexto mejora la precisión y la retención del alumno. Los estudiantes adquieren dominio del idioma rápidamente con nuestro "Método de Inmersión Virtual"',
    ReadWhitePaperButton: 'Lea nuestra guía detallada',
    ItAllAddsUpTitle: 'No son solo palabras',
    ItAllAddsUpSubtitle:
      'El aprendizaje de idiomas en vivo en nuestros mundos virtuales en línea es realmente efectivo',
    ItAllAddsUpCard1Title: 'La atención en el aprendizaje es esencial',
    ItAllAddsUpCard1Description:
      'Los estudios mostraron que el 92 % de los estudiantes estuvieron atentos durante las lecciones en 3D frente al 46 % de atención durante las lecciones que no eran en 3D.',
    ItAllAddsUpAttribution1: 'LiFE Research',
    ItAllAddsUpCard2Title: 'Los estudiantes tienen éxito',
    ItAllAddsUpCard2Description:
      'Los estudiantes que participaron en cursos en 3D lograron ganancias de creatividad significativamente mayores desde el punto de vista estadístico que el grupo de estudiantes que participaron en un entorno de aula tradicional',
    ItAllAddsUpAttribution2: 'Dr. Linda Bradford',
    ItAllAddsUpCard3Title: 'Todo cuenta y suma',
    ItAllAddsUpCard3Description:
      'Los estudios de investigación mostraron que el 86 % de los estudiantes que estudiaron en 3D mostraron mejoras significativas en la comprensión y la retención en comparación con el 52 % en las lecciones en 2D.',
    ItAllAddsUpAttribution3: 'LiFE Research',
    OurPartnersTitle: 'Nuestros Aliados',
    TheFinalWordTitle: 'La Última Palabra',
    TheFinalWordOverlayDescription:
      'Literalmente he estado en miles de reuniones en mi carrera empresarial. Pero reunirme en la plataforma FluentWorlds Academy ha sido la MEJOR EXPERIENCIA DE REUNIÓN que he tenido.',
    TheFinalWordOverlayAttribution:
      'Adam Smith, Director de Ingeniería de Soluciones para Unity, la principal plataforma de desarrollo de videojuegos del mundo',
  },
  paymentSuccessPage: {
    title: 'FluentWorlds Academy',
    confirmationMessage:
      'Gracias por comprar el curso del FluentWorlds Academy: {{priceName}}! Elija la fecha de inicio de su clase entre las opciones a continuación.',
    goBackButton: 'Mi Cuenta',
    loadingError: 'Hubo un error al cargar los datos de la clase.',
    loading: 'Cargando...',
    processing: 'Processando...',
    startDateLabel: 'Fecha de Inicio: ',
    endDateLabel: 'Fecha Final: ',
    classTimes: 'Horarios de Clases Semanales: ',
    // TODO: need to get translations for all days of the week and logic to set them.
    classDescriptionLabel: 'Descripción: ',
    classStudents: 'Estudiantes: ',
    register: 'Registrarse',
    duplicateRegistrationMessage:
      'Ya estás registrado para esta clase. No puedes registrarte para la misma clase dos veces.',
    generalRegistrationErrorMessage:
      'Hubo un error. Vuelva a intentarlo. Si el problema persiste, póngase en contacto con el soporte de FluentWorlds.',
  },
  paymentCancelPage: {
    title: 'Academia FluentWorlds',
    heading: 'Anulando el pago',
    subHeading:
      'No se te cobrará ya que anulaste en medio del proceso de pago.',
    goBackButton: 'VOLVER A LA CUENTA',
  },
  eventPage: {
    generatingLink:
      'Espere mientras generamos la URL del evento programado de 3Dmeet…',
    noPurchasesMessage: 'No ha pagado por ningún evento programado',
    unauthorized: 'No eres profesor',
    errorMessageNoCourseAccess:
      'No tiene acceso a este evento o es posible que se haya producido un error',
    invalidEventId: 'El ID del evento no es válido',
  },
  errorPage: {
    notFoundOne: '404 No encontrado',
    notFoundTwo: 'No hay contenido en este enlace',
  },
  layout: {
    title: 'Academia FluentWorlds',
  },
  subscriptionProvider: {
    errorMessageStripeBillingUrl:
      'Error al recuperar la URL de facturación de Stripe',
    errorMessageNoCourseAccess: 'No tienes acceso a este curso',
    errorMessageFallback:
      'Se produjo un error. Vuelve a intentarlo o comunícate con el servicio de asistencia.',
  },
  userProvider: {
    deleteAccountPrompt: 'Estás seguro de que deseas eliminar tu cuenta?',
    errorMessageFallback:
      'Se produjo un error. Vuelve a intentarlo o comunícate con el servicio de asistencia.',
  },
  uniminutoJoinClassPage: {
    errorMessage: 'Se ha producido un error. Inténtalo de nuevo.',
    sessionLengthValidation: 'Debe tener 10 caracteres',
    displayNameMinValidation: 'Debe tener 2 caracteres o más',
    displayNameMaxValidation: 'Debe tener 20 caracteres o menos',
    formFieldRequired: 'Requerido',
    displayNameInitialValue: 'Estudiante',
    pageTitle: 'Unirse a la clase | Academia FluentWorlds',
    formHeader: 'Unirse a la clase',
    sessionInputLabel: 'Sesión',
    displayNameInputLabel: 'Entre aqui su Nombre y Apellido',
    displayNameInputSubtitle: 'Enre solo su primer nombre y su primer apellido',
    formSubmitButtonText: 'Unirse',
  },
  uniminutoWorksheetsPage: {
    header: 'Hojas de trabajo',
    pageTitle: 'Hojas de trabajo | FluentWorlds Academy',
  },
  uniminutoFriendsAndFamilyPage: {
    pageTitle: 'Uniminuto Amigos y Familiares',
    registerForDiscountedCourse: 'Regístrese para un curso con descuento',
  },
  courseCardComponent: {
    title: 'Sign-up today for your online english course',
    description:
      "Learning English can change your life, don't delay another day, make the decision today to register for online english courses with FluentWorlds",
    register: 'Regístrese para Curso Completo',
    registerNow: 'COMPRE AHORA',
    registrationDeadline: 'Fecha Límite de Inscripción',
    buyNow: 'COMPRA AHORA',
    attendSession: 'Asistir Sesión',
    teachSession: 'Enseñar Sesión',
    taughtCoursesHeading: 'Tus Cursos Impartidos',
    nextSession: 'Próxima Sesión',
    coursePurchased: 'Curso Comprado',
    noCoursesAvailable: 'No hay cursos disponibles',
    durationUnit: 'Sesiones',
    dateFormat: 'LLL',
    timeFormat: 'h a',
    daysOfWeek: {
      monday: 'lunes',
      tuesday: 'martes',
      wednesday: 'miércoles',
      thursday: 'jueves',
      friday: 'viernes',
      saturday: 'sábado',
      sunday: 'domingo',
    },
    dayRange: '{{first}} y {{second}}',
    timeRange: '{{beginning}} a {{end}} {{timezone}}',
    timezones: {},
  },
  subscriptionEventList: {
    userAllowancePhrase:
      'Has programado {{usedEventAllowance}} de {{maxEventAllowance}} eventos para este mes',
    noEventsScheduled: 'No hay eventos programados',
    registerNow: 'REGÍSTRATE AHORA',
    closeModalButton: 'Éxito',
    modalContentLabel: 'Programar lecciones en grupos pequeños',
  },
  subscriptionEventCardComponent: {
    attendButton: 'Asistir',
    teachButton: 'Enseñar',
    dateFormat: 'dddd, MMMM D, YYYY h:mm A',
    minutes: '{{duration}} minutos',
    eventName: 'Lecciones en grupos pequeños',
  },
}

export default Spanish
