const English = {
  modalOffer: {
    title: 'Happy Holidays!',
    text:
      'Provide this coupon when you check out for 50% off any FluentWorlds Academy course!',
  },
  general: {
    error: 'An error has occurred. Please try again.',
  },
  header: {
    greeting: 'Hello',
    home: 'Home',
    courses: 'Courses',
    account: 'My Account',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    howItWorks: 'How It Works',
    enterprise: 'For Enterprises',
  },
  footer: {
    footerText: 'FluentWorlds Academy | All Rights Reserved',
  },
  indexPage: {
    seoTitle: 'English Classes Online - Beginners to Professionals',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    videoNotSupported: 'Your browser does not support the video tag.',
    LearnInMetaverseTitle: 'Learn English in the Metaverse',
    LearnInMetaverseSubtitle: 'The complete language learning experience',
    howItWorksTitle: 'How It Works',
    howItWorksSubtitle: 'Live Language Learning in our Virtual Online Worlds',
    howItWorksCard1Title:
      'Online Language Learning in Our Live Interactive Virtual Worlds',
    howItWorksCard1Description:
      'Choose small group or individual classes. Speak English with classmates at your level or have individual, one-to-one tutoring with our expert instructors who will guide you on your journey to English fluency.',
    howItWorksCard2Title: 'Learn English in the Metaverse',
    howItWorksCard2Description:
      "Studies show that virtual learning in immersive, 3D environments is much more effective than traditional classrooms or standard online training. Life isn't two-dimensional, so why should your English course be?",
    howItWorksCard3Title: 'Accelerated Learning',
    howItWorksCard3Description:
      'Take your English language learning further. Access our top-rated learning products: PerfectAccent and teh FluentWorlds App. Immersion plus speech recognition = a supercharged learning experience.',
    howItWorksLearnMore: 'Learn More',
    titleAndImageTitle: 'Immerse Yourself in our Metaverse',
    titleAndImageSubtitle: 'The complete Language Learning Experience',
    meetOurStudentsTitle: 'Meet Our Students',
    benefitsTitle: 'Benefits of Learning With FluentWorlds',
    benefitsSmallTitle1: 'Start Learning English Today',
    benefitsSmallDescription1:
      'Book your course today and start learning immediately with access to the FluentWorlds app. The most exciting, immersive, and effective language learning app ever.',
    benefitsSmallTitle2: 'Learn Your Way, on Your Time',
    benefitsSmallDescription2:
      'Take complete control of your language learning. Choose from our different course options ot suit your needs. Customise your character in the Metaverse, and decide if your webcam is on or off.',
    benefitsSmallTitle3: 'Create a Winning Habit',
    benefitsSmallDescription3:
      "Studying English in FluentWorlds Academy is never boring. You'll become fluent by simulating real life experiences. This helps you make quick progress and retain the language.",
    benefitsSmallTitle4: 'Make Your Language Learning Go Further',
    benefitsSmallDescription4:
      'Our teachers are experts at teaching in the Metaverse. They will guid you through our carefully created English language curriculum in a creative, memorable and effective way.',
    comingSoon: 'Video Coming Soon',
    closeVideo: '[Close Video]',
  },
  buyNowButton: {
    buyNowButton: 'Buy Now',
  },
  learnWithoutLimits: {
    learnWithoutLimitsTitle: 'Learn Without Limits in the Metaverse',
    learnWithoutLimitsSubtitle: 'Choose your course',
    learnWithoutLimitsGeneralHeader: 'General English',
    learnWithoutLimitsGeneralDescription:
      'Improve your everyday English by practicing the language you need in realistic environments such as a grocery store, airport, bank or government office. Make big improvements quickly.',
    learnWithoutLimitsBusinessHeader: 'Business English',
    learnWithoutLimitsBusinessDescription:
      'Become an effective and successful business communicator and get results in the workplace. From the boardroom to the conference center, learn real skills to boost your career.',
    learnWithoutLimitsAcademicHeader: 'Academic English',
    learnWithoutLimitsAcademicDescription:
      'Better your skills and grades and get ahead in your studies on our virtual academic campuses. Improve your academic knowledge and gain confidence to study in English.',
    learnWithoutLimitsIndividualHeader: 'Individual English',
    learnWithoutLimitsIndividualDescription:
      'Learning built just for you. Get private, focussed individual lessons with one of our expert teachers. Explore virtual environments and achieve your personal language goals.',
  },
  meetOurStudents: {
    meetOurStudentsBuyNow: 'Buy Now',
    comingSoon: 'Video Coming Soon',
    closeVideo: '[Close Video]',
  },
  howItWorksPage: {
    seoTitle: 'English Classes Online - Beginners to Professionals',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    howDoesFWAWorkTitle: 'Learn English in the Metaverse',
    howDoesFWAWorkSubtitle: 'The complete language learning experience',
    howDoesCard1Title: 'Choose The Right Course For You',
    howDoesCard1Description: 'Take a short English test to help you decide',
    howDoesCard2Title: 'Learn Immediately With Our Apps',
    howDoesCard2Description: 'All access to FluentWorlds and PerfectAccent',
    howDoesCard3Title: 'Start Live Classes With Your Teacher',
    howDoesCard3Description: 'Full immersive classes in the Metaverse',
    howDoesCard4Title: 'Track Your Progress',
    howDoesCard4Description: 'Monitor your improvement for best results',
    howDoesCard5Title: 'Results And Certification',
    howDoesCard5Description: 'Download your official certificate',
    howDoesCard6Text:
      "Start Your Journey Today... You'll be on your way to a brighter future",
    immersiveLearningTitle: 'Immersive Learning With English Teachers',
    immersiveLearningSubtitle:
      'Perfect your accent with our North American teachers and award-winning apps',
    immersiveCardBullet1:
      'Hear and speak real-world English, either one-to-one or in small groups. Collaborate with your friends and students from around the world',
    immersiveCardBullet2:
      'Online classes are held in over 50 unique virtual environments',
    immersiveCardBullet3:
      'Practice and perfect your pronunciation at any time with our Perfect Accent App',
    immersiveCardBullet4: "FluentWorlds App is rated 5 stars on Apple's iTunes",
    groupOrPrivateTitle: 'Group Classes or Private Classes',
    groupOrPrivateSubtitle: 'Choose the way you learn best',
    groupCardTitle: 'Group Classes',
    groupCardBullet1: 'Learn with others in a relaxed, supportive environment',
    groupCardBullet2:
      'One hour classes are offered each week. Choose a schedule and frequency that best suits you',
    groupCardBullet3: 'Group classes usually hold 10-15 students',
    groupCardBullet4: 'Choose between 5, 10 or 20 lessons per month',
    groupCardBullet5:
      'Colloborate with fellow students on fresh perspectives and insights',
    privateCardTitle: 'Private One-On-One Classes',
    privateCardBullet1:
      'One-on-one classes maximize time with your instructor. You are the focus of the class. A great way to eliminate distractions and boost productivity.',
    privateCardBullet2: 'Choose a schedule and frequency that best suits you',
    privateCardBullet3:
      'Curriculum is tailored to your specific needs and goals',
    privateCardBullet4: 'Enjoy immediate and helpful feedback',
    privateCardBullet5: 'Choose from 5, 10 or 20 lessons per month',
    titleAndImageTitle: 'Join Thousands of Happy Students',
    titleAndImageSubtitle: "You'll be glad you did!",
  },
  accountPage: {
    seoTitle: 'FluentWorlds Academy - My Account',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    tooEarlyToJoinMessage:
      'You cannot enter the classroom until the first day of classes.',
    tooLateToJoinMessage: 'This class has ended.',
    individualClassCardMessage: 'Your tutor will reach out with more details.',
    accountDetailsNav: 'Account Details',
    yourClassesNav: 'Your Classes',
    signOutNav: 'Sign Out',
    yourClassesHeader: 'Your Classes',
    classStatusLabel: 'Status: ',
    classStatusRegistered: 'Registered',
    classStatusPurchased: 'Purchased',
    classStatusCompleted: 'Completed',
    classStartDateLabel: 'Start Date: ',
    classJoinButton: 'Join Class',
    classSelectButton: 'Select Class',
    accountDetilsHeader: 'Account Details:',
    accountDetailsSubHeader:
      'Note: if you change your email or username you will need to log in again.',
    showUsernameLabel: 'Username: ',
    updateUsername: 'Update Username: ',
    usernameToastError: 'New Username field is empty.',
    showEmailLabel: 'Email: ',
    updateEmail: 'Update Email: ',
    emailToastError: 'New Email field is empty.',
    updateButton: 'Update',
    profileDetailsHeader: 'Profile Details',
    profileDetailsSubHeader:
      'If you\'d like to update your profile details, please fill out the form below and click "Update Profile"',
    showFullNameLabel: 'Full Name: ',
    showNativeLanguageLabel: 'Native Language: ',
    showAgeLabel: 'Age: ',
    showPronounsLabel: 'Pronouns: ',
    selectFirstItem: '-- select an option --',
    pronounsSheHer: 'She/Her',
    pronounsHeHim: 'He/Him',
    pronounsTheyThem: 'They/Them',
    pronounsPreferNotToSay: 'I prefer not to say',
    showEnglishProficiencyLabel: 'English Proficiency: ',
    proficiencyLevel1: 'Level 1 (A1/B1)',
    proficiencyLevel2: 'Level 2 (B1)',
    proficiencyLevel3: 'Level 3 (B2+)',
    proficiencyUnsure: 'Not Sure',
    updateProfileButton: 'Update Profile',
    loading: 'Loading...',
    processing: 'Processing...',
    notLoggedIn:
      'You are not logged in. Please sign-in or register to view your account details.',
    formError: 'You must fill out all profile details before submitting.',
    noClassesMessage:
      'You have not yet purchased any classes. When you do, you will see them here.',
    notApplicable: 'N/A',
    usernameSuccessMessage: 'Username successfully updated',
    emailSuccessMessage: 'Email successfully updated',
    profileSuccessMessage: 'Profile successfully updated',
  },
  selectAClassPage: {
    seoTitle: 'FluentWorlds Academy - Select Your Class',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    selectAClassTitle:
      'Select your preferred class schedule from the options below.',
    startDate: 'Start Date: ',
    endDate: 'End Date: ',
    classTimes: 'Weekly Class Times: ',
    // TODO: need to get translations for all days of the week and logic to set them.
    classDescription: 'Description: ',
    classStudents: 'Students: ',
    loading: 'Loading...',
    register: 'Register',
    duplicateRegistrationMessage:
      'You are already registered for this class. You cannot register for the same class twice.',
  },
  coursesPage: {
    seoTitle: 'FluentWorlds Academy - English Courses',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    processing: 'Processing...',
    mustBeSignedInMessage:
      'You must be signed-in to make a purchase. If you do not have an account yet use the "Sign In" button above to create a free account.',
    coursesPageTitle: 'English Courses',
    coursesPageSubtitle: 'We have a course for you!',
    coursesPageInstructions:
      'Select your course using the dropdown menus below. Pick from General, Academic, or Business classes - or get a one-on-one tutor.',
    generalEnglishTitle: 'General English',
    businessEnglishTitle: 'Business English',
    academicEnglishTitle: 'Academic English',
    individualEnglishTitle: 'Individual English',
    liteLabel: 'Lite',
    classicLabel: 'Classic',
    premiumLabel: 'Premium',
    completeLabel: 'Complete',
    nonIndividualLiteDuration: '4 Weeks / 8 Classes',
    nonIndividualClassicDuration: '8 Weeks / 16 Classes',
    nonIndividualPremiumDuration: '12 Weeks / 24 Classes',
    nonIndividualCompleteDuration: '16 Weeks / 32 Classes',
    individualLiteDuration: '5 Hours of Tutoring',
    individualClassicDuration: '10 Hours of Tutoring',
    individualPremiumDuration: '15 Hours of Tutoring',
    checkbox1: 'Engaging and immersive live lessons with our expert teachers',
    checkbox2:
      'Unlimited access to interactive self-study lessons on the FluentWorlds app',
    checkbox3: 'Level placement test and end-of-course certification',
    upgradeCheckboxesTitle1: 'Available in Classic or Premium packages',
    upgradeCheckboxesTitle2: 'Available in Classic or Premium packages',
    checkbox4: 'Access to PerfectAccent',
    checkbox5_1:
      'End-of-course tutorial with your teacher (Premium and Complete group courses only)',
    checkbox5_2: '(Premium and Complete group courses only)',
    moreBelow: 'More Course Information Below',
    selectCategory: 'Select Category:',
    selectOption: 'Select Option:',
    generalEnglishInfoHeader: 'Learn General English in the Metaverse',
    generalEnglishInfoTitle: 'Practical Lessons for Everyday Life',
    generalEnglishInfoDescription1:
      'Make everyday life easier by learning vocabulary you will actually use. Whether you need help with travel, ordering at a restaurant, or simply going to the grocery store, our General English course will make you a pro in no time.',
    businessEnglishInfoHeader: 'Learn Business English in the Metaverse',
    businessEnglishInfoTitle: 'For All Your Career Needs',
    businessEnglishInfoDescription1:
      'Working internationally requires a wide range of language skills and competencies. Business leaders must present effectively, contribute in meetings, manage negotiations, write effective emails, and speak on the phone or online. Our Business English courses prepare you to succeed in all of these scenarios.',
    academicEnglishInfoHeader: 'Learn Academic English in the Metaverse',
    academicEnglishInfoTitle: 'Campus Life Was Never Easier',
    academicEnglishInfoDescription1:
      'Get ahead in your studies with our Academic English. This course covers vocabulary unique to your university environment. Gain confidence in your speaking and writing skills. Using these advanced skills you will excel in important areas such as research group presentations, critical analysis, and extended writing.',
    individualEnglishInfoHeader: 'Learn Individual English in the Metaverse',
    individualEnglishInfoTitle: 'Your Own Personal Guide For English',
    individualEnglishInfoDescription1:
      'Our individual tutoring service is tailored to you. Lessons are based on your specific goals and needs. Lessons are available all day and you can choose the best time for you, including evenings and weekends.',
    InfoBullet1: 'Live, online English Conversation classes',
    InfoBullet2: 'Expert American Teachers',
    InfoBullet3: 'Immersion in real-world places in a video game',
    InfoBullet4: 'Fast and fun - Superior learning at great prices',
    InfoBullet5: 'Proven to be the Fastest Way to learn to speak English',
    InfoSeparator1: 'Choose from',
    InfoSeparatorNumberThree: 'three',
    InfoSeparatorNumberFour: 'four',
    InfoSeparator2: 'different programs:',
    InfoBullet6: '4 weeks - brush up on your English skills',
    InfoBullet7: '8 weeks - accelerate your learning',
    InfoBullet8: '12 weeks - perfect for leveling up',
    InfoBullet9: '16 weeks - our complete course',
    InfoBullet6Individual: '5 Hours of Personal Tutoring',
    InfoBullet7Individual: '10 Hours of Personal Tutoring',
    InfoBullet8Individual: '15 Hours of Personal Tutoring',
    studentComponent: 'What Our Students Are Saying',
    titleAndImageTitle: 'Join Thousands of Happy Students',
    titleAndImageSubtitle: "You'll be glad you did!",
    backToTop: 'Back To Top',
    buyNowButton: 'Buy Now',
  },
  enterprisePage: {
    seoTitle: 'FluentWorlds Academy - For Enterprises',
    seoDescription:
      'Immersion is proven to be the fastest way to learn English. Our live online English classes, with expert American teachers, allows you to experience classes in a 3D world.',
    BusinessInTheMetaverseTitle: 'Business English In The Metaverse',
    BusinessInTheMetaverseSubtitle:
      'Immersive language training for your staff in stunning 3d virtual environments. Our system is proven to accelerate learning and deliver incredible results.',
    BusinessInTheMetaverseContactUs: 'Want to learn more? Contact us at ',
    ourSuiteSweetTitle: 'Our Suite. Sweet.',
    ourSuiteSweetSubtitle: 'The Complete Language Learning Experience',
    fluentWorldsProductTitle: 'FluentWorlds',
    fluentWorldsProductDescription:
      'The most exciting, immersive, and effective language-learning app ever.',
    FWAProductTitle: 'FluentWorlds Academy / 3Dmeet',
    FWAProductDescription:
      'Expert personal tutoring and engaging web conferencing at your fingertips',
    perfectAccentProductTitle: 'Perfect Accent',
    perfectAccentProductDescription:
      'Precise and gentle accent guidance powered by advanced machine learning',
    acceleratedLearningHeader: 'Accelerated Learning',
    acceleratedLearningTitle1: 'Bring Your Team Together',
    acceleratedLearningDescription1:
      'No matter where they are based, your team will collaborate, learn and flourish in our engaging virtual environments. Bring them together to improve their English language communication skills.',
    acceleratedLearningTitle2: 'Learn English in 3D',
    acceleratedLearningDescription2:
      'Learning and remembering a language is proven to be more effective in 3D environments than in traditional classrooms or standard online training.',
    acceleratedLearningTitle3: 'The Complete Solution',
    acceleratedLearningDescription3:
      'With access to our suite of learning products including PerfectAccent, our unique blend of live immersion plus speech recognition equals a faster learning experience. Easiliy track progress with FluentViewer, our remarkable dashboard tool that allows administrators and teachers to see student progress.',
    acceleratedLearningTitle4: 'Tailored to You',
    acceleratedLearningDescription4:
      'Brand our environments with your logo and colours, or let us build a completely bespoke world for your team to learn in.',
    acceleratedLearningSeeHowItWorks: 'See How Your Course Works',
    CEOTestimonialDescription:
      "Through the 3D Meet app, we feel like we are immersed in the classroom with the teacher. I find it valuable that we share the experience with other people which allows dialogue about daily life to be transferred to the English language. Also, the FluentWorlds app is a virtual tool we can use independently for homework practice after class. This allows us to further strengthen our learning because it's not linear traiing; it is a dynamic, playful experience with an educational objective",
    MasteryMethodologyTitle: 'Mastery With Our Methodology',
    MasteryMethodologySubtitle:
      'Proof that immersion produces a more effective and enjoyable learning experience',
    MasteryMethodologyOverlayTitle:
      'Professors Use Immersion For Better Learning',
    MasteryMethodologyOverlayDescription:
      'Our teachers use immersive, virtual world environments to simulate real-world experiences. Studies show that language practiced in context improves learner accuracy and retention. Students acquire language proficiency quickly with our "Virtual Immersion Method"',
    ReadWhitePaperButton: 'Read Our White Paper',
    ItAllAddsUpTitle: 'Not Just Lip Service',
    ItAllAddsUpSubtitle:
      'Live Language Learning in Our Virtual Online Worlds Is Effective',
    ItAllAddsUpCard1Title: 'Attention Is Priceless',
    ItAllAddsUpCard1Description:
      'Studies showed that 92% of students were attentive during 3D lessons vs. 46% attentive during non-3D lessons.',
    ItAllAddsUpAttribution1: 'LiFE Research',
    ItAllAddsUpCard2Title: 'Students Succeed',
    ItAllAddsUpCard2Description:
      'Students who participated in 3D coursework achieved statistically significantly greater creativity gains than control group of students who participated in traditional classroom setting',
    ItAllAddsUpAttribution2: 'Dr. Linda Bradford',
    ItAllAddsUpCard3Title: 'It All Adds Up',
    ItAllAddsUpCard3Description:
      'Research Studies showed that 86% of students who studied in 3D showed significant improvements in understanding and retention compared to 52% in 2D lessons',
    ItAllAddsUpAttribution3: 'LiFE Research',
    OurPartnersTitle: 'Our Partners',
    TheFinalWordTitle: 'The Final Word',
    TheFinalWordOverlayDescription:
      '"I literally have been to 1000\'s of meetings in my business career. But meeting in the FluentWorlds Academy platform has been the BEST MEETING EXPERIENCE I have ever had."',
    TheFinalWordOverlayAttribution:
      "- Adam Smith, Sr. Director of Solutions Engineering for Unity, the World's top Game Developer platform",
  },
  paymentSuccessPage: {
    title: 'FluentWorlds Academy',
    confirmationMessage:
      'Thank you for purchasing our FluentWorlds Academy Course: {{priceName}}! Please select your class start date from the options below.',
    goBackButton: 'My Account',
    loadingError: 'There was an error loading the class data.',
    loading: 'Loading...',
    processing: 'Processing...',
    startDateLabel: 'Start Date: ',
    endDateLabel: 'End Date: ',
    classTimesLabel: 'Weekly Class Times: ',
    // TODO: need to get translations for all days of the week and logic to set them.
    classDescriptionLabel: 'Description: ',
    classStudentsLabel: 'Students: ',
    register: 'Register',
    duplicateRegistrationMessage:
      'You are already registered for this class. You cannot register for the same class twice.',
    generalRegistrationErrorMessage:
      'There was an error. Please try again, if the issue persists please contact FluentWorlds support',
  },
  paymentCancelPage: {
    title: 'FluentWorlds Academy',
    heading: 'Cancelling checkout',
    subHeading:
      'You will not be charged since you cancelled in-between the checkout process.',
    goBackButton: 'GO BACK TO ACCOUNT',
  },
  eventPage: {
    generatingLink:
      'Please wait while we generate your 3Dmeet scheduled event URL...',
    noPurchasesMessage: 'You have not paid for any scheduled events',
    unauthorized: 'You are not a teacher',
    errorMessageNoCourseAccess:
      "You don't have access to this event or an error may have occurred",
    invalidEventId: 'The Event ID is Invalid',
  },
  errorPage: {
    notFoundOne: '404 Not Found',
    notFoundTwo: "This page doesn't exist",
  },
  layout: {
    title: 'FluentWorlds Academy',
  },
  subscriptionProvider: {
    errorMessageNoCourseAccess: "You don't have access to this course",
    errorMessageFallback:
      'An error has occurred, please try again or contact support.',
  },
  userProvider: {
    deleteAccountPrompt: 'Are you sure you want to delete your account?',
    errorMessageFallback:
      'An error has occurred, please try again or contact support.',
  },
  uniminutoJoinClassPage: {
    errorMessage: 'An error has occurred. Please try again.',
    sessionLengthValidation: 'Must be 10 characters',
    displayNameMinValidation: 'Must be 2 characters or more',
    displayNameMaxValidation: 'Must be 20 characters or less',
    formFieldRequired: 'Required',
    displayNameInitialValue: 'Student',
    pageTitle: 'Join Class | FluentWorlds Academy',
    formHeader: 'Join Class',
    sessionInputLabel: 'Session',
    displayNameInputLabel: 'Display Name',
    displayNameInputSubtitle: '',
    formSubmitButtonText: 'Join',
  },
  uniminutoWorksheetsPage: {
    header: 'Worksheets',
    pageTitle: 'Worksheets | FluentWorlds Academy',
  },
  uniminutoFriendsAndFamilyPage: {
    pageTitle: 'Uniminuto Friends and Family',
    registerForDiscountedCourse: 'Register for a Discounted Course',
  },
  courseCardComponent: {
    title: 'Sign-up today for your online english course',
    description:
      "Learning English can change your life, don't delay another day, make the decision today to register for online english courses with FluentWorlds",
    register: 'Register for Full Course',
    registerNow: 'PURCHASE NOW',
    registrationDeadline: 'Registration Deadline',
    buyNow: 'BUY NOW',
    attendSession: 'Attend Session',
    teachSession: 'Teach Session',
    taughtCoursesHeading: 'Your Taught Courses',
    nextSession: 'Next Session',
    coursePurchased: 'Course Purchased',
    noCoursesAvailable: 'No courses available',
    durationUnit: 'Sessions',
    dateFormat: 'LLL',
    timeFormat: 'h a',
    daysOfWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    dayRange: '{{first}} / {{second}}',
    timeRange: '{{beginning}} - {{end}} {{timezone}}',
    timezones: {},
  },
  subscriptionEventList: {
    userAllowancePhrase:
      'You have scheduled {{usedEventAllowance}} of {{maxEventAllowance}} events for this month',
    noEventsScheduled: 'No events scheduled',
    registerNow: 'REGISTER NOW',
    closeModalButton: 'Close',
    modalContentLabel: 'Schedule Small Group Lessons',
  },
  subscriptionEventCardComponent: {
    attendButton: 'Attend',
    teachButton: 'Teach',
    dateFormat: 'dddd, MMMM Do, YYYY h:mm A',
    minutes: '{{duration}} minutes',
    eventName: 'Small Group Lessons',
  },
}

export default English
