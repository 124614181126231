import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { toast } from 'react-toastify'

import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import UserReducer from 'context/user/UserReducer'
import {
  LOAD_USER_STATE,
  UPDATE_USERNAME,
  DELETE_USER,
  UPDATE_USER_PRODUCT_ACCESS,
  SET_USER_STATE_LOADING,
  UPDATE_USER_FWA_PRODUCT,
  UPDATE_EMAIL,
  TOGGLE_SHOW_DISCOUNT,
} from '../types'

const UserProvider = ({ children }) => {
  const initialState = {
    username: '',
    subscriptionInfo: null,
    userStateLoading: false,
    productAccess: null,
    firestoreUserObject: null,
    showDiscount: false,
  }

  const { t } = useTranslation('userProvider')
  const [state, dispatch] = useReducer(UserReducer, initialState)

  const { auth0User, logout, getTokenSilently } = useAuth0()

  const setUserStateLoading = (isUserStateLoading) =>
    dispatch({ type: SET_USER_STATE_LOADING, payload: isUserStateLoading })

  const displayBasicErrorHandling = () => toast.error(t('errorMessageFallback'))

  const fetchUserFromFirestore = async () => {
    try {
      setUserStateLoading(true)
      //fail gracefully if we are waiting for Auth0 to finish loading
      if (!auth0User) return setUserStateLoading(false)
      const { sub } = auth0User

      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              userById(id: "${sub}") {
                username
                email
                subscriptionInfo {
                  customerId
                },
                fwaPurchases {
                  classId,
                  priceId,
                  priceName,
                  productId,
                  productName,
                  purchaseDate,
                  purchaseId,
                  status
                },
                fwaProfile {
                  fullName,
                  nativeLanguage,
                  age,
                  pronouns,
                  englishLevel,
                  isTeacher,
                }
              }
            }
          `,
        },
      })

      if (
        response.data.data !== null &&
        response.data.data.userById !== null &&
        response.data.data.userById.username !== null
      ) {
        const { userById } = response.data.data
        await dispatch({
          type: LOAD_USER_STATE,
          payload: userById,
        })

        return setUserStateLoading(false)
      } else {
        console.error('Error retrieving data for user.')
        displayBasicErrorHandling()
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const fetchUserProductAccess = async () => {
    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const response = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              getUserProductAccess {
                fluentworldsAcademyGeneralLite
                fluentworldsAcademyGeneralClassic
                fluentworldsAcademyGeneralPremium
                fluentworldsAcademyGeneralComplete
                fluentworldsAcademyAcademicLite
                fluentworldsAcademyAcademicClassic
                fluentworldsAcademyAcademicPremium
                fluentworldsAcademyBusinessLite
                fluentworldsAcademyBusinessClassic
                fluentworldsAcademyBusinessPremium
                fluentworldsAcademyIndividualLite
                fluentworldsAcademyIndividualClassic
                fluentworldsAcademyIndividualPremium
                isFluentWorldsAcademyTeacher
              }
            }
          `,
        },
      })

      if (response.data.data.getUserProductAccess) {
        const { getUserProductAccess } = response.data.data

        await dispatch({
          type: UPDATE_USER_PRODUCT_ACCESS,
          payload: getUserProductAccess,
        })

        return setUserStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setUserStateLoading(false)
    }
  }

  const updateUserName = async (newUserName) => {
    const { sub } = auth0User

    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            updateUser(input: {id: "${sub}", username:"${newUserName}"}) {
              id
              username
            }
          }
        `,
        },
      })

      if (
        firebaseResponse.data.data.updateUser &&
        firebaseResponse.data.data.updateUser.id &&
        firebaseResponse.data.data.updateUser.username
      ) {
        dispatch({
          type: UPDATE_USERNAME,
          payload: newUserName,
        })
        setUserStateLoading(false)
        return true
      } else {
        toast.error(firebaseResponse.data.errors[0].message)
        setUserStateLoading(false)
        return false
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setUserStateLoading(false)
    }
  }

  const updateEmail = async (newEmail) => {
    const { sub } = auth0User

    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            updateUser (
              input: {
                id: "${sub}", 
                email: "${newEmail}",
              }
            ) 
            {
              id
              email
            }
          }
        `,
        },
      })

      console.log(firebaseResponse)

      if (
        firebaseResponse.data.data.updateUser &&
        firebaseResponse.data.data.updateUser.id &&
        firebaseResponse.data.data.updateUser.email
      ) {
        dispatch({
          type: UPDATE_EMAIL,
          payload: newEmail,
        })
        setUserStateLoading(false)
        return true
      } else {
        toast.error(firebaseResponse.data.errors[0].message)
        setUserStateLoading(false)
        return false
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setUserStateLoading(false)
    }
  }

  const deleteUser = async () => {
    const { sub } = auth0User
    const accessToken = await getTokenSilently()

    if (window.confirm(t('deleteAccountPrompt'))) {
      setUserStateLoading(true)

      await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            deleteUser(id: "${sub}") {
              id
            }
          }
            `,
        },
      })
        .then((firebaseResponse) => {
          setUserStateLoading(false)
          dispatch({
            type: DELETE_USER,
          })
          return logout()
        })
        .catch((error) => {
          console.error(error)
          displayBasicErrorHandling()
          return setUserStateLoading(false)
        })
    }
  }

  const updateUserFWAProduct = async (newPurchaseObject) => {
    const { sub } = auth0User

    try {
      setUserStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_USERS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            updateUser(input: {
              id: "${sub}", 
              fwaPurchases: [{
                purchaseId: "${newPurchaseObject.purchaseId}",
                purchaseDate: "${newPurchaseObject.purchaseDate}",
                classId: "${newPurchaseObject.classId}",
                productId: "${newPurchaseObject.productId}",
                productName: "${newPurchaseObject.productName}",
                priceId: "${newPurchaseObject.priceId}",
                priceName: "${newPurchaseObject.priceName}",
                status: "${newPurchaseObject.status}"
              }]
            }) 
            {
              fwaPurchases {
                purchaseId,
                purchaseDate,
                classId,
                productId,
                productName,
                priceId,
                priceName,
                status,
              }
            }
          }
        `,
        },
      })

      if (firebaseResponse.data.data.updateUser !== null) {
        await dispatch({
          type: UPDATE_USER_FWA_PRODUCT,
          payload: firebaseResponse.data.data.updateUser,
        })
      }

      setUserStateLoading(false)
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setUserStateLoading(false)
    }
  }

  const toggleShowDiscount = async (boolValue) => {
    dispatch({
      type: TOGGLE_SHOW_DISCOUNT,
      payload: boolValue,
    })
  }

  return (
    <UserContext.Provider
      value={{
        username: state.username,
        subscriptionInfo: state.subscriptionInfo,
        productAccess: state.productAccess,
        userStateLoading: state.userStateLoading,
        firestoreUserObject: state.firestoreUserObject,
        showDiscount: state.showDiscount,
        fetchUserFromFirestore,
        fetchUserProductAccess,
        updateUserName,
        updateEmail,
        deleteUser,
        updateUserFWAProduct,
        toggleShowDiscount,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
