import React, { useReducer } from 'react'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useAuth0 } from 'utils/react-auth0-spa'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import SubscriptionReducer from 'context/subscription/SubscriptionReducer'
import {
  GET_TEACHERS,
  GET_USER_FWA_EVENTS,
  SET_SUBSCRIPTION_STATE_LOADING,
  SET_SESSION_ACCESS,
  SET_SESSION_ACCESS_ERROR,
  SET_STRIPE_SESSION_DATA,
} from '../types'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const SubscriptionProvider = ({ children }) => {
  const initialState = {
    teachers: [],
    subscriptionStateLoading: false,
    meetingUrl: null,
    isSessionAccessError: false,
    userFWAEvents: null,
    stripeSessionDetails: null,
  }

  const { t } = useTranslation('subscriptionProvider')
  const [state, dispatch] = useReducer(SubscriptionReducer, initialState)

  const { getTokenSilently } = useAuth0()

  const setSubscriptionStateLoading = (isSubscriptionStateLoading) =>
    dispatch({
      type: SET_SUBSCRIPTION_STATE_LOADING,
      payload: isSubscriptionStateLoading,
    })

  const setSessionAccessError = (isSessionAccessError) =>
    dispatch({
      type: SET_SESSION_ACCESS_ERROR,
      payload: isSessionAccessError,
    })

  const displayBasicErrorHandling = () => toast.error(t('errorMessageFallback'))

  const getTeachers = async () => {
    try {
      setSubscriptionStateLoading(true)

      const firebaseResponse = await axios({
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          query: `
            query {
              getTeachers {
                teacherId
                translations {
                  en {
                    teacherName
                    courseTitle
                    description
                    image
                  }
                  es {
                    teacherName
                    courseTitle
                    description
                    image
                  }
                }
              }
            }
        `,
        },
      })

      if (firebaseResponse.data.data.getTeachers) {
        const { getTeachers } = firebaseResponse.data.data
        dispatch({
          type: GET_TEACHERS,
          payload: getTeachers,
        })
        return setSubscriptionStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createStripeSubscriptionSession = async (stripePriceId) => {
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          mutation {
            createSubscriptionSession(input: {
              stripePriceId: "${stripePriceId}",
              isAcademy: true
            }) {
              stripeCheckoutSessionId
            }
          }
        `,
        },
      })
      const {
        stripeCheckoutSessionId,
      } = firebaseResponse.data.data.createSubscriptionSession

      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId,
      })

      if (error) {
        throw new Error(error)
      }

      return setSubscriptionStateLoading(false)
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const createStripeCheckoutSession = async (productId, priceId) => {
    const SUCCESS_PATH = '/payment/success/'
    const CANCEL_PATH = '/payment/cancel/'
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              createStripeCheckoutSession(input: {
                productId: "${productId}",
                priceId: "${priceId}",
                redirectSuccess: "${process.env.GATSBY_WEBSITE_URL}${SUCCESS_PATH}",
                redirectCancel: "${process.env.GATSBY_WEBSITE_URL}${CANCEL_PATH}"
              }) {
                checkoutSession {
                  id
                }
              }
            }
          `,
        },
      })

      const {
        checkoutSession,
      } = firebaseResponse.data.data.createStripeCheckoutSession

      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.id,
      })

      if (error) {
        throw new Error(error)
      }

      return setSubscriptionStateLoading(false)
    } catch (error) {
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const getProductPricing = async (productId, currencies) => {
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              GetProductPricing(input: {productId: "${productId}", currencies: "${currencies}"}) {
                prices {
                  id
                }
              }
            }
          `,
        },
      })

      const { prices } = firebaseResponse.data.data.getProductPricing

      setSubscriptionStateLoading(false)
      return prices
    } catch (error) {
      displayBasicErrorHandling()
      setSubscriptionStateLoading(false)
      return null
    }
  }

  const getSessionAccess = async (eventId) => {
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `query {
            GetSessionAccess(input: {
              eventId: "${eventId}"
            }) {
              meetingUrl
            }
          }`,
        },
      })

      if (firebaseResponse.data.data.getSessionAccess) {
        const { meetingUrl } = firebaseResponse.data.data.getSessionAccess
        setSessionAccessError(false)

        dispatch({
          type: SET_SESSION_ACCESS,
          payload: meetingUrl,
        })

        setSubscriptionStateLoading(false)
      } else {
        toast.error(t('errorMessageNoCourseAccess'))
        setSubscriptionStateLoading(false)
        setSessionAccessError(true)
      }
    } catch (error) {
      displayBasicErrorHandling()
      setSubscriptionStateLoading(false)
      setSessionAccessError(true)
    }
  }

  const createBillingPortalSession = async () => {
    try {
      await setSubscriptionStateLoading(true)

      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          query {
            getStripeBillingPortalSession(input: {
              isAcademy: true
            }) {
              url
            }
          }
        `,
        },
      })

      const { url } = firebaseResponse.data.data.getStripeBillingPortalSession

      if (url) {
        await setSubscriptionStateLoading(false)
        return url
      } else {
        throw new Error(t('errorMessageFallback'))
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      return setSubscriptionStateLoading(false)
    }
  }

  const getUserFWAEvents = async () => {
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()

      const firebaseResponse = await axios({
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
            query {
              getUserFWAEvents {
                id
                name
                createdDate
                startDate
                endDate
                status
              }
            }
          `,
        },
      })

      if (firebaseResponse.data.data.getUserFWAEvents) {
        const { getUserFWAEvents } = firebaseResponse.data.data
        dispatch({
          type: GET_USER_FWA_EVENTS,
          payload: getUserFWAEvents,
        })
        setSubscriptionStateLoading(false)
      } else if (firebaseResponse.data.errors) {
        displayBasicErrorHandling()
        console.error(firebaseResponse.data.errors)
        setSessionAccessError(true)
        setSubscriptionStateLoading(false)
      }
    } catch (error) {
      console.error(error)
      displayBasicErrorHandling()
      setSessionAccessError(true)
      setSubscriptionStateLoading(false)
    }
  }

  const getStripeSessionDetails = async (sessionId) => {
    // this should receive a stripe session ID, and use that ID to retrieve that particular sessions details. Then save those to the state.
    try {
      setSubscriptionStateLoading(true)
      const accessToken = await getTokenSilently()
      const firebaseResponse = await axios({
        method: 'post',
        url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          query: `
          query {
            getStripeSessionDetails(input: {
              stripeSessionId: "${sessionId}",
            }) {
              sessionId,
              purchaseDate,
              productId,
              productName,
              priceId,
              priceName,
              paymentIntent
            }
          }
        `,
        },
      })
      dispatch({
        type: SET_STRIPE_SESSION_DATA,
        payload: firebaseResponse.data.data.getStripeSessionDetails,
      })
      setSubscriptionStateLoading(false)
    } catch (err) {
      console.error(err)
      displayBasicErrorHandling()
      setSessionAccessError(true)
      setSubscriptionStateLoading(false)
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        teachers: state.teachers,
        teacherMeetingUrl: state.meetingUrl,
        meetingUrl: state.meetingUrl,
        subscriptionStateLoading: state.subscriptionStateLoading,
        isSessionAccessError: state.isSessionAccessError,
        userFWAEvents: state.userFWAEvents,
        stripeSessionDetails: state.stripeSessionDetails,
        getTeachers,
        createStripeSubscriptionSession,
        createStripeCheckoutSession,
        getProductPricing,
        createBillingPortalSession,
        getSessionAccess,
        getUserFWAEvents,
        getStripeSessionDetails,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
