// user
export const LOAD_USER_STATE = 'LOAD_USER_STATE'
export const UPDATE_USERNAME = 'UPDATE_USERNAME'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER_PRODUCT_ACCESS = 'UPDATE_USER_PRODUCT_ACCESS'
export const SET_USER_STATE_LOADING = 'SET_USER_STATE_LOADING'
export const UPDATE_USER_FWA_PRODUCT = 'UPDATE_USER_FWA_PRODUCT'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const TOGGLE_SHOW_DISCOUNT = 'TOGGLE_SHOW_DISCOUNT'

// subscription
export const GET_TEACHERS = 'GET_TEACHERS'
export const GET_USER_FWA_EVENTS = 'GET_USER_FWA_EVENTS'
export const SET_SUBSCRIPTION_STATE_LOADING = 'SET_SUBSCRIPTION_STATE_LOADING'
export const SET_SESSION_ACCESS = 'SET_SESSION_ACCESS'
export const SET_SESSION_ACCESS_ERROR = 'SET_SESSION_ACCESS_ERROR'
export const SET_STRIPE_SESSION_DATA = 'SET_STRIPE_SESSION_DATA'
